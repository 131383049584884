import 'chartjs-adapter-dayjs-4'
import 'dayjs/locale/de'
import {
  ArcElement,
  Chart,
  Colors,
  Filler,
  Legend,
  LineController,
  LineElement,
  LinearScale,
  PieController,
  PointElement,
  TimeScale,
  Tooltip
} from 'chart.js'
import { TreemapController, TreemapElement } from 'chartjs-chart-treemap'
import { autoSubmitForm, capFirst, localize, mount, ready, toPercentage } from '#js/components/utils'
import { gettext, interpolate } from '#js/components/i18n'
import AnnotationPlugin from 'chartjs-plugin-annotation'
import { color } from 'chart.js/helpers'
import dayjs from 'dayjs'
import { fetchJSON } from '#js/components/http'

dayjs.locale(globalThis.language)

export function axisLabels (value, unit) {
  if (unit) {
    return `${localize(value)}\xa0${unit}`
  } else {
    return localize(value)
  }
}

export function toPercentHex (value) {
  const hex = Math.round(value * 255).toString(16)
  return hex.length === 1 ? '0' + hex : hex
}

export function handleHover (evt, item, legend) {
  legend.chart.data.datasets.forEach((dataset, index) => {
    if (index === item.datasetIndex) {
      dataset.backgroundColor = dataset.backgroundColor.slice(0, 7) + toPercentHex(0.75)
      dataset.borderColor = dataset.borderColor.slice(0, 7) + toPercentHex(1)
    } else {
      dataset.backgroundColor = dataset.backgroundColor.slice(0, 7) + toPercentHex(0.15)
      dataset.borderColor = dataset.borderColor.slice(0, 7) + toPercentHex(0.3)
    }
  })
  legend.chart.update()
}

export function handleLeave (evt, item, legend) {
  legend.chart.data.datasets.forEach((dataset) => {
    dataset.backgroundColor = dataset.backgroundColor.slice(0, 7) + '66'
    dataset.borderColor = dataset.borderColor.slice(0, 7) + 'FF'
  })
  legend.chart.update()
}

Chart.register(
  Legend,
  LineController,
  LinearScale,
  PointElement,
  LineElement,
  Colors,
  Filler,
  TimeScale,
  Tooltip,
  PieController,
  ArcElement,
  TreemapElement,
  TreemapController,
  AnnotationPlugin
)

export function handleNoData (chart) {
  const firstDataset = chart.data.datasets[0]
  let hasData
  if (chart.config.type === 'treemap') {
    // We are dealing with a treemap chart
    // It is considered dataless if tree is an empty list
    hasData = firstDataset.tree.length !== 0
  } else if (chart.config.type === 'pie') {
    hasData = firstDataset.data.length !== 0
  } else {
    // We are dealing with a line chart
    hasData = firstDataset.data.some((item) => item.y !== 0)
  }

  if (!hasData) {
    const chartContext = chart.ctx

    // rerender chart content to highlight missing data
    chart.clear()
    chartContext.save()
    chartContext.textAlign = 'center'
    chartContext.textBaseline = 'middle'
    chartContext.fillText(capFirst(gettext('no data available yet')), chart.width / 2, chart.height / 2)
    chartContext.restore()

    // Hide download buttons for respective chart
    const chartName = chart.canvas.getAttribute('data-chart-name')
    const downloadForm = document.getElementById(`form-downloads-${chartName}`)
    downloadForm.classList.add('hidden')
  }
}

const emptyGraphPlugin = {
  id: 'emptyGraph',
  afterDraw: handleNoData
}

export function getFillValue (isStacked, metric) {
  // We don't want to fill the line when we are showing a partial total
  // because for the partial we want the focus on the points and not on
  // the area.
  if (isStacked && metric === 'running_total') {
    return 'origin'
  } else {
    return false
  }
}

export function getAnnotationLabel (label, description) {
  return {
    text: capFirst(label) + ': ' + description,
    fillStyle: 'black',
    pointStyle: 'dash',
    lineDash: [3, 2],
    lineWidth: 2.5
  }
}

export function generateLabels (lineAnnotation) {
  return function (chart) {
    const labels = Chart.defaults.plugins.legend.labels.generateLabels(chart)
    labels.forEach((label, index) => {
      const labelTitle = capFirst(chart.data.datasets[index].label)
      const footnote = chart.data.datasets[index].footnote
      label.text = footnote ? `${labelTitle}: ${footnote}` : labelTitle
    })
    if (lineAnnotation) {
      const annotation = JSON.parse(lineAnnotation)
      const [annotationLabel, annotationDescription] = annotation.slice(2)
      labels.push(getAnnotationLabel(annotationLabel, annotationDescription))
    }
    return labels
  }
}

export function getLineAnnotation (lineAnnotation) {
  const annotation = JSON.parse(lineAnnotation)
  const [annotationStart, annotationEnd] = annotation.slice(0, 2).map(x => Math.ceil(x))
  const [annotationLabel] = annotation.slice(2, -1)
  return {
    scaleId: 'y',
    type: 'line',
    borderColor: 'black',
    borderDash: [6, 6],
    borderWidth: 2,
    yMin: annotationStart,
    yMax: annotationEnd,
    label: {
      backgroundColor: color('#000').alpha(0.6).rgbString(),
      color: '#fff',
      content: capFirst(annotationLabel),
      display: true,
      position: 'end'
    }
  }
}

export function getAnnotation (annotation) {
  return { annotations: annotation ? [getLineAnnotation(annotation)] : [] }
}

export async function lineChart (element) {
  const data = await fetchJSON(element.dataset.url)

  const isStacked = !!element.dataset.stacked
  data.datasets[0].fill = getFillValue(isStacked, element.dataset.metric)

  return new Chart(element.getContext('2d'), {
    type: 'line',
    data,
    options: {
      locale: document.documentElement.lang,
      maintainAspectRatio: false,
      responsive: true,
      elements: {
        line: {
          // if stacked, fill lines to the previous dataset
          fill: isStacked ? '-1' : 'origin'
        }
      },
      scales: {
        y: {
          display: true,
          suggestedMin: parseInt(element.dataset.y) || 0,
          stacked: isStacked,
          ticks: {
            beginAtZero: true,
            callback: (value) => axisLabels(value, element.dataset.unit)
          }
        },
        x: {
          type: 'time',
          time: {
            unit: element.dataset.timescale,
            tooltipFormat: element.dataset.timescale === 'month' ? 'MMM YYYY' : 'DD. MMM YYYY'
          }
        }
      },
      plugins: {
        legend: {
          onHover: handleHover,
          onLeave: handleLeave,
          position: 'bottom',
          align: 'start',
          labels: {
            usePointStyle: true,
            padding: 20,
            generateLabels: generateLabels(element.dataset.annotation)
          }
        },
        tooltip: {
          usePointStyle: true,
          callbacks: {
            label: (tooltipItem) => {
              const label = capFirst(tooltipItem.dataset.label || '')
              if (tooltipItem.parsed.y !== null) {
                return label + ': ' + axisLabels(tooltipItem.parsed.y, element.dataset.unit)
              }
            },
            footer: function (tooltipItems) {
              if (tooltipItems.length > 1) {
                return interpolate(gettext('Total: %s'), [axisLabels(tooltipItems.reduce((a, e) => a + e.parsed.y, 0), element.dataset.unit)])
              }
            }
          }
        },
        annotation: getAnnotation(element.dataset.annotation)
      },
      interaction: {
        mode: 'index',
        intersect: false
      },
      animation: {
        onComplete: prepareImageDownload
      }
    },
    plugins: [emptyGraphPlugin]
  })
}

export function prepareImageDownload (element) {
  const chartName = element.chart.canvas.getAttribute('data-chart-name')
  const link = document.getElementById(`chart-download-${chartName}`)
  link.download = `${chartName}-${new Date().toISOString().split('T')[0]}.png`
  link.href = element.chart.toBase64Image()
}

export function handlePieHoverLegend (evt, legendItem, legend) {
  // Set the respective arc element to active and show the tooltip
  const meta = legend.chart.getDatasetMeta(0)
  const arc = meta.data[legendItem.index]
  arc.active = true
  const tooltip = legend.chart.tooltip
  tooltip.setActiveElements([
    {
      datasetIndex: 0,
      index: legendItem.index
    }])
  legend.chart.update()
}

export function setPieChartTooltip (tooltipItem) {
  if (tooltipItem.parsed !== null) {
    return interpolate(gettext('%s of all bookings'), [toPercentage(tooltipItem.parsed, 2)])
  }
}

export function handlePieLeaveLegend (evt, legendItem, legend) {
  // Set the respective arc element to inactive
  const meta = legend.chart.getDatasetMeta(0)
  const arc = meta.data[legendItem.index]
  arc.active = false // This also hides the tooptip
  legend.chart.update()
}

export async function pieChart (element) {
  const data = await fetchJSON(element.dataset.url)
  data.labels = data.datasets[0].labels

  return new Chart(element.getContext('2d'), {
    type: 'pie',
    data,
    options: {
      locale: document.documentElement.lang,
      maintainAspectRatio: false,
      responsive: true,
      animation: {
        onComplete: prepareImageDownload
      },
      plugins: {
        legend: {
          onHover: handlePieHoverLegend,
          onLeave: handlePieLeaveLegend,
          position: 'left',
          align: 'center',
          maxWidth: 200
        },
        tooltip: {
          usePointStyle: true,
          callbacks: {
            label: setPieChartTooltip
          }
        }
      }
    },
    plugins: [emptyGraphPlugin]
  })
}

export async function treemapChart (element) {
  const datasets = await fetchJSON(element.dataset.url)
  const data = datasets.datasets[0]
  const categoryLookup = data.category_lookup
  const otherSubcategories = data.other_subcategories

  return new Chart(element.getContext('2d'), {
    type: 'treemap',
    data: {
      datasets: [
        {
          key: 'value',
          groups: ['category', 'subcategory'],
          tree: data.tree,
          borderWidth: 0,
          hoverColor: function (context) {
            const item = context.dataset.data[context.dataIndex]
            if (!item) { return }

            if (item._data.category) {
              const firstChildColor = color(item._data.children[0].color)
              if (item.l === 0) {
                // The root node should only slightly be highlighted
                return firstChildColor.rgbString()
              } else {
                return 'white'
              }
            }
          },
          backgroundColor: function (context) {
            const item = context.dataset.data[context.dataIndex]
            if (!item) { return }

            const firstChildColor = color(item._data.children[0].color)
            // white background for the root node
            if (item.l === 0) {
              return firstChildColor.alpha(0.1).rgbString()
            }

            if (item._data.category) {
              return firstChildColor.desaturate(item._data.value).darken(item._data.value).rgbString()
            }
          },
          hoverBackgroundColor: function (context) {
            const item = context.dataset.data[context.dataIndex]
            if (!item) { return }

            if (item._data.category) {
              const firstChildColor = color(item._data.children[0].color)
              if (item.l === 0) {
                // The root node should only slightly be highlighted
                return firstChildColor.alpha(0.2).rgbString()
              } else {
                return firstChildColor.rgbString()
              }
            }
          },
          labels: {
            align: 'left',
            display: true,
            formatter (item) { return item.raw.g },
            color: 'white',
            font: {
              size: 16,
              weight: 'normal'
            },
            position: 'center'
          },
          captions: {
            align: 'left',
            display: true,
            formatter (item) {
              const data = item.raw._data
              return categoryLookup[data.category] + ': ' + toPercentage(data.value, 1)
            },
            color: function (context) {
              const item = context.dataset.data[context.dataIndex]
              const firstChildColor = color(item._data.children[0].color)
              return firstChildColor.rgbString()
            },
            font: {
              size: 16,
              weight: 'bold'
            },
            padding: 3
          }
        }
      ]
    },
    options: {
      locale: document.documentElement.lang,
      maintainAspectRatio: false,
      responsive: true,
      animation: {
        onComplete: prepareImageDownload
      },
      plugins: {
        title: {
          display: true
        },
        legend: {
          display: false
        },
        tooltip: {
          titleFont: {
            size: 10,
            weight: 'bold'
          },
          callbacks: {
            title (items) {
              if (items.length > 1) { // this is a subcategory
                return items[1].raw.g ? items[1].raw.g : ''
              } else {
                const item = items[0]
                return `${categoryLookup[item.raw.g]}: ${toPercentage(item.raw._data.value, 1)}`
              }
            },
            label (item) {
              // Do not show root node tooltip in other nodes
              const rawContext = item.raw
              if (rawContext.l === 0) { return null }

              let label = interpolate(gettext('%s of all bookings'), [toPercentage(rawContext.v, 1)])
              const category = rawContext._data.category
              if (rawContext._data.children[0].is_other_subcategory && category !== 'other') {
                // List consolidated subcategories in tooltip
                // category 'other' is a special case, because we
                // derive the subcategories from the subcategory_extra_info
                return [label].concat(
                  '\n',
                  gettext('Contains: '),
                  otherSubcategories[category].map(
                    subcategory => `• ${subcategory[0]}`
                  )
                )
              } else {
                const toolTipExtraInfo = rawContext._data.children[0].subcategory_extra_info
                if (toolTipExtraInfo) {
                  label = [label].concat(
                    '\n',
                    gettext('Contains: '),
                    toolTipExtraInfo.map(
                      line => `• ${line}`
                    )
                  )
                }
                return label
              }
            }
          }
        }
      }
    },
    plugins: [emptyGraphPlugin]
  })
}

mount(lineChart, '.line-chart')
mount(pieChart, '.pie-chart')
mount(treemapChart, '.treemap-chart')

ready(() => {
  document.querySelectorAll('[data-toggle=chart-config]').forEach(form => {
    autoSubmitForm(form.name)
  })
})
